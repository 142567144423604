$bg: #f6f4f2
$bs: #d9d9d9
$fg: #2e2c2c
$fs: #14181b
$gr: #6a6968
$rd: #ff3944
$bl: #0074D9
$gn: #19bf62
$yl: #ffad1f
$or: #f45d22
$pr: #794bc4
$text: #121212
$font_size: 18px

.post
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 2em
  padding: 3% 0
  overflow: scroll
  position: relative
  color: #121212
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif
  .banner
    width: 100%
    border-radius: 12px
    margin: 1em 0
    img
      width: 100% !important
      padding: 0
      height: auto
      border-radius: 12px
  h1
    font-weight: 700
    color: $fg
    font-size: 32px
    margin: 1em 0 0.1em
    padding-bottom: 1em
    width: 100%
    text-align: center
    color: $fg
    font-size: 28px
    width: 100%
    font-family: "rel"
  h2
    font-size: 28px
    color: $fg
    font-weight: bolder
    text-align: left
    padding-bottom: 0.25em
    width: 100%
    font-family: "rel"
    margin: 1em 0 0
  h3
    font-size: 18px
    color: $fg
    font-weight: bolder
    font-family: "rel"
  h5
    font-size: 14px
    color: $gr
    text-align: left
    font-family: "rel"
    margin: 0
    img
      width: 10px
      height: auto
      align-items: baseline
  h6
    font-size: 14px
    color: $gr
    margin: 0.5em 0 2em 0
    width: 100%
    font-family: "rel"
    text-align: center
  hr
    display: block
    height: 10px
    width: 40vw
    border-bottom: none
    border-left: none
    border-right: none
    border-top: 1px solid #ddd
    margin: 1em 0
    padding: 0
  p
    font-size: $font_size
    font-weight: 400
    text-align: justify
    line-height: 30px
    margin: 1em 0 1em 0
    color: $fg
    strong
      font-weight: 500
  blockquote
    margin: 1em 0
    background: rgba(0,0,0,0.05)
    width: 100%
    border-radius: 5px
    font-style: italic
    padding: .2em 1em
  p.ar
    color: $fs
    font-size: $font_size
    text-align: center
    span.h
      color: $gr
      font-size: 1em
  ul
    text-align: left
    margin: 0
  li
    line-height: 1.5em
    text-align: left
    margin: 0
    padding: 0
    font-size: $font_size
    strong
      font-weight: 500
  a
    text-decoration: none
    color: $bl
    transition: color 0.25s ease-in-out
  a:visited
    color: #0169bd
  a:hover
    color: #03a2ef
  pre
    border-radius: 5px
    width: 100%
    padding: 1em 0
  @media (max-width: 768px)
    padding: 0
    border-radius: 0
    border: 1px #cdcdcd
    border-top-style: solid
    h1
      font-size: 22px
    h2
      font-size: 20px
    h3
      font-size: 16px
    h6
      font-size: 10px
    li
      font-size: 18px
    p
      font-size: 18px
      text-align: left
      line-height: 28px
      font-weight: 400
      letter-spacing: -.003em
    blockquote
      margin: 1em
      padding: 0 2em
      font-size: 16px
      background: rgba(0, 0, 0, .05)
      width: 100%
      border-radius: 5px
      font-style: italic