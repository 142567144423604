@import url(https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@200;400&display=swap);

@font-face {
  font-family: "rel";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/relative.ttf) format("truetype")
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

a {
  color: inherit;
  text-decoration: none
}

* {
  box-sizing: border-box
}

canvas {
  z-index: -100;
  left: 0;
  top: 0;
  position: fixed
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

.content {
  width: 100%;
  height: 100%
}

.app,
.content {
  display: flex;
  flex-direction: column
}

.app {
  width: 70vw;
  height: 50vh;
  align-content: center;
  align-items: flex-start;
  z-index: 200
}

@media only screen and (max-width:1000px) {

  body,

  .app {
    width: 100vw;
    padding: 10%
  }
}

.transition-group {
  flex: 1 1;
  position: relative
}

.page,
.router {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.fade-enter .page {
  opacity: 0;
  z-index: 1
}

.fade-enter.fade-enter-active .page {
  opacity: 1;
  transition: opacity .45s ease-in-out
}

.fade-exit .page {
  opacity: 0;
  z-index: 1
}

.fade-exit.fade-exit-active .page {
  opacity: 0;
  transition: opacity .2s ease-in-out
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

main {
  display: block
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

h3 a {
  text-decoration: none;
  color: #0074d9;
  transition: color .25s ease-in-out
}

h3 a:visited {
  color: #0169bd
}

h3 a:hover {
  color: #03a2ef
}